import React from 'react';  
import logo from './logo1.jpg';  
import './App.css';  
  
function App() {  
  return (  
    <div className="App">  
      <header className="App-header">  
        <img src={logo} className="image"  />  
        <p>  
          Welcome To Viator Solvere.  
  
       
        </p>  
        <a  
          className="App-link"  
          href="http://www.viatorsolvere.com/"  
          target="_blank"  
          rel="noopener noreferrer"  
        >  
          Book your tickets  
        </a>  
      </header>  
    </div>  
  );  
}  
  
export default App;  